import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import { SectionHeading } from "components/misc/Headings";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

function createData(priority, responsetime, desciption) {
  return { priority, responsetime, desciption };
}

const issues = [
  createData('P1', '4 Hours', 'Major outage, core functionality unavailable with all users affected.'),
  createData('P2', '1 Working Day', 'Core functionality unavailable, impacting some users.'),
  createData('P3', '7 Working Days', 'Bugs/defects affecting multiple users or defects preventing normal system operation affecting multiple users.'),
  createData('P4', '14 Working Days', 'Bugs/defects altering the intended system operation without preventing normal operation.'),

];

const requests = [
  createData('P1', '3 Working Days', 'Security questions, concerns and clarification.'),
  createData('P2', '10 Working Days', 'Advice, guidance, help and support relating to TenantWise.'),
  createData('P3', '20 Working Days', 'Contractual, billing, support service questions and clarification.'),
  createData('P4', '30 Working Days', 'New feature recomendations and feedback. (Not including a decision or delivery of the feature recomendation)'),

];

export default ({ headingText = "TenantWise Support Services Policy" }) => {
  return (
    <StyledDiv>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: 19th December 2022</p>

            <h2>Support Services Coverage</h2>
            <p>
              This support services policy applies only to paying customers of the TenantWise product.
              In order to recieve the support detailed below you and/or your organisation must posess a valid TenantWise portal Software Service Agreement.
            </p>
            <p>
              The support services provided relate only to the TenantWise product developed by CodeByTwo and its associated infrastructure. 
              Systems beyond the control of CodeByTwo are not covered under this policy, for example, a customers integrated housing management or payment system.
            </p>

            <h2>Support Hours</h2>
            <p>Monday to Friday 09:00 - 17:00 UK Local Time <span className="italic">(excluding bank holidays and public holidays)</span></p>

            <h2>Requesting Support</h2>
            <p>
              To request support services you must raise a support ticket via our support portal accessable in the bottom right of any page on this website or by <a href="https://codebytwo.atlassian.net/servicedesk/customer/portal/1">clicking here.</a>
            </p>

            <h2>Support Type Definitions</h2>
            <p>The support services are split into two categories, Issues and Requests. Each category has its own set of service level priorities.</p>
            <p><strong>Issue Definition</strong></p>
            <p>
              An issue is something affecting the functionality or performance of the TenantWise product.
            </p>

            <p><strong>Request Definition</strong></p>
            <p>
              A request is something that may need modifying, improving or changing. A request can also be in the form or qestions and product clarification.
            </p>
          </Text>

          <Text>
            <h2>Issue Priorities</h2>
          </Text>
          
          <TableContainer component={Paper}  variant="outlined">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Priority</TableCell>
                  <TableCell align="left">Resolution time</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issues.map((issue) => (
                  <TableRow
                    key={issue.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {issue.priority}
                    </TableCell>
                    <TableCell align="left">{issue.responsetime}</TableCell>
                    <TableCell align="left">{issue.desciption}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Text>
            <h2>Request Priorities</h2>
          </Text>
          
          <TableContainer component={Paper}  variant="outlined">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Priority</TableCell>
                  <TableCell align="left">Resolution time</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.map((request) => (
                  <TableRow
                    key={request.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {request.priority}
                    </TableCell>
                    <TableCell align="left">{request.responsetime}</TableCell>
                    <TableCell align="left">{request.desciption}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Text>
            <h2>Support Escalation</h2>
            <p>In the event that you are not satisfied with the way your ticket is being dealt with, you can email your allocated point of contact.</p>
          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </StyledDiv>
  );
};
