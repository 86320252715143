import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnDark";
import SnagglistScreenshot from "images/snagglistscreenshot.jpeg";
import ShopifyScreenshot from "images/shopifydemo.png";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled="true">
   
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading style={{color: '#ee5c4d'}}>Services</Subheading>}
        heading={
          <>
            We have a wide range of services to suit business needs
          </>
        }
      />
      {/* <MainFeature id='products'
        subheading={<Subheading style={{color: '#293241'}}>Our Applications</Subheading>}
        imageSrc={SnagglistScreenshot}
        imageBorder={true}
        imageDecoratorBlob={false}
        heading='Snagglist.com'
        description="Snagglist is a web and mobile application developed for property developers and their customers. Snagglist is designed to make property snagging easier whilst providing property developers with realtime insights."
        primaryButtonText = "Take a look"
        primaryButtonUrl = "https://snagglist.com"
      />
       <MainFeature id='products'
        subheading={<Subheading style={{color: '#293241'}}>E-Commerce Solutions</Subheading>}
        imageSrc={ShopifyScreenshot}
        imageBorder={true}
        imageDecoratorBlob={false}
        textOnLeft={false}
        heading="Shopify Experts"
        description="We are experts in eCommerce and have partnered with Shopify to enable us to provide the best possible solutions. Take a look at our demo shop below, the password is 'amazing'"
        primaryButtonText = "View our demo"
        primaryButtonUrl = "https://clothbytwo.codebytwo.com/"
      /> */}
      <FAQ
        subheading={<Subheading style={{ color: '#ee6c4d'}}>ABOUT US</Subheading>}
        heading={
          <>
            Want to <HighlightedText style={{ color: '#ee6c4d'}}>learn more?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What do you do?",
            answer:
              "We build solutions to solve problems. We won't re-invent the wheel, if there is a solution fit for your business, we will find it. If not, we'll make it."
          },
          {
            question: "How do you do it?",
            answer:
              "First off, we'll have a chat to figure out what it is you are looking for. Once we have an understanding of your requirements, we will create a project scope. This is what we will use to ensure we deliver what you are looking for."
          },
          {
            question: "What frameworks and infrastructure do you use?",
            answer:
              "We use a range of cutting edge frameworks and hosting services hosted on Amazon Web Services."
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
