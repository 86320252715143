import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import { SectionHeading } from "components/misc/Headings";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Privacy Policy" }) => {
  return (
    <StyledDiv>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: 26th September 2022</p>

            <p>
              <h2>Our contact details:</h2>
              Name: CodeByTwo Limited	<br/>
              Address: 10 Empire Drive, Carterton, OX18 1BY <br/>
              E-mail: support@codebytwo.com
            </p>

            <h2>The type of personal information we collect</h2>
            <p>We currently collect and process the following information:</p>
            <p><strong>Customer Portal (collected from you at registration):</strong></p>
            <ul>
              <li>
                  First and last name(s)
              </li>
              <li>
                  Email address
              </li>
              <li>
                  Tenant reference numbers (I.E. your rent account reference number)
              </li>
              <li>
                  Date of birth
              </li>
            </ul>
            <p><strong>Customer Portal (collected from your housing provider after registration):</strong></p>
            <ul>
              <li>
                  Rent account balance and transactions
              </li>
              <li>
                  Contact details such as phone numbers and email addresses
              </li>
              <li>
                  Agreement and account information
              </li>
              <li>
                  Property repair information
              </li>
              <li>
                  Requests made to the housing provider
              </li>
            </ul>

            <h2>How we get the personal information and why we have it</h2>
            <p>
              Most of the personal information we process is provided to us directly by you for one of the following reasons:
            </p>
            <ul>
              <li>To provide our service to you as a user</li>
            </ul>

            <p>
              We use the information that you have given us in order to:
            </p>
            <ul>
              <li>To provide you access to our platform</li>
              <li>To link your user account with housing provider</li>
              <li>To provide you with digital services related to your tenancy agreement with your housing provider</li>
            </ul>
            <p>
              We may share this information with:
            </p>
            <ul>
              <li>Your housing provider (landlord)</li>
            </ul>
            <p>
              Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are: 
              <br/>
              <strong>We have a legitimate interest.</strong>
            </p>
            
            <h2>How we store your personal information </h2>
            <p>
              Your information is securely stored in European data centres.
            </p>
            <p>
              We keep your information for as long as you and your housing provider needs it. It is your housing providers responsibility to manage the retention of your data.
            </p>
            
            <h2>Your data protection rights</h2>
            <p>
              Under data protection law, you have rights including: <br/>
              <strong>Your right of access</strong> - You have the right to ask us for copies of your personal information. <br/>
              <strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. <br/>
              <strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances. <br/>
              <strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances. <br/>
              <strong>Your right to object to processing</strong> - You have the the right to object to the processing of your personal information in certain circumstances. <br/>
              <strong>Your right to data portability</strong> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances. <br/>
            </p>

            <p>
              You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. 
            </p>
            <p>
              Please contact us at support@codebytwo.com if you wish to make a request.
            </p>

            <h2>How to complain</h2>
            <p>
              If you have any concerns about our use of your personal information, you can make a complaint to us at:
            </p>
            <p>
              support@codebytwo.com
            </p>
            <p>
              You can also complain to the ICO if you are unhappy with how we have used your data. <br/><br/>
              <strong>The ICO’s address: <br/></strong>
              Information Commissioner’s Office <br/>
              Wycliffe House <br/>
              Water Lane <br/>
              Wilmslow <br/>
              Cheshire <br/>
              SK9 5AF
            </p>
            <p>Helpline number: 0303 123 1113</p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </StyledDiv>
  );
};
