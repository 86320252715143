import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import HomePage from "pages/HomePage"
import TermsOfServicePage from "pages/TermsOfService";
import PrivacyPolicyPage from "pages/PrivacyPolicy";
import TenantWiseSupportPolicy from "pages/TenantWiseSupportPolicy";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        {/* <Route path="/terms" exact>
          <TermsOfServicePage />
        </Route> */}
        <Route path="/privacypolicy" exact>
          <PrivacyPolicyPage />
        </Route>
        <Route path="/tenantwise-support-services-policy" exact>
          <TenantWiseSupportPolicy />
        </Route>
      </Switch>
    </Router>
  );
}